(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Dependencies:
     *    - icon
     *    - font-awesome
     */
    Vue.component("crud-favlist", {
        props: [
            "isModalOpen", // boolean
            "remaining", // int      - optional (only creation)
            "listId", // int      - optional (only edit)
            "i18n",
        ],
        data: function () {
            return {
                maxNameInputLength: MAX_INPUT_CHARS, // int
                isPrivate: false, // boolean
                isPrivateDirty: false, // boolean
                nameInput: "", // String
                nameInputDirty: false, //boolean
                oldListName: "",
            };
        },
        template: `
<div v-if="isModalOpen" class="crud-favlist--mask"
   :aria-labelledby="title"
   aria-modal="true"
   :aria-hidden="isModalOpen"
   ref="createListModal"
   v-cloak>
  <div class="crud-favlist__inner">
    <div class="crud-favlist__content" ref="createListModalContent">
      <div class="crud-favlist__header">
        <div class="crud-favlist__header-info">
          <div class="crud-favlist__title">
            {{ title }}
          </div>
          <div v-if="!isEdit" class="crud-favlist__remaining">
              {{ remaining }} {{i18n.txtRemaining}}
          </div>
        </div>
        <div class="crud-favlist__close">
          <button type="button" class="crud-favlist__close-button" aria-label="Close" @click="onClickClose">
            <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg">
          </button>
        </div>
      </div>
      <div class="crud-favlist__body">
        <div class="crud-favlist__body-info">
          <div class="crud-favlist__name">
            {{ i18n.txtNameList }}
          </div>
          <div class="crud-favlist__count">
            {{ inputCharLength }} / {{ maxNameInputLength }}
          </div>
        </div>
        <input ref="nameInputRef" v-model="nameInput" type="text" class="crud-favlist__input" :placeholder="i18n.txtPlaceholderList" maxlength="40">
        <div class="crud-favlist__private">
          <div class="crud-favlist__private-checkbox">
            <input v-model="isPrivate" class="form-check-input crud-favlist__private-checkbox-input" type="checkbox">
            <span @click="togglePrivate" class="crud-favlist__private-checkbox-text">{{i18n.txtPrivate}}</span>
            <i class="icon icon-lock-closed crud-favlist__private-checkbox-icon"></i>
          </div>
          <div v-show="isPrivate" class="crud-favlist__private-msg">
            <p>{{i18n.txtPrivacyHelp}}</p>
          </div>
        </div>
      </div>
      <div v-bind:class="footerClass()">
        <button v-if="isEdit" type="button" class="btn btn-gray pull-left" v-on:click="onClickDelete">
          <i class="fa fa-trash-o"></i>
        </button>
        <button type="button" class="btn btn-primary btn-success crud-favlist__footer-action" v-on:click="onClickSubmit">
          {{ mainActionText }}
        </button>
      </div>
    </div>
  </div>
</div>
`,
        methods: {
            onClickSubmit: onClickSubmit,
            onClickDelete: onClickDelete,
            onClickClose: onClickClose,
            onClickOutside: onClickOutside,
            footerClass: footerClass,
            createList: createList,
            editList: editList,
            togglePrivate: togglePrivate,
        },
        computed: {
            isEdit: isEdit,
            inputCharLength: inputCharLength,
            title: title,
            mainActionText: mainActionText,
        },
        watch: {
            isModalOpen: isModalOpen,
            nameInput: nameInput,
            isPrivate: isPrivate,
        },
        //VUE LIFECYCLE
        mounted: mounted,
        beforeDestroy: beforeDestroy,
    });

    /**
     * -----------------
     * ---- CONSTANTS --
     * -----------------
     */
    let MAX_INPUT_CHARS = 40;

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */
    function onClickSubmit() {
        if (!this.isEdit) {
            if (this.nameInput.length > 0) {
                // only create when input was set
                this.createList();
            }
        } else {
            this.editList();
        }
    }

    function createList() {
        let url = window.location.origin + "/wikiloc/create-favorite-list.do";

        let params = "";
        params += "name=" + encodeURIComponent(this.nameInput);
        params += "&public=" + !this.isPrivate;

        _ajaxPost(url, params, (data) => {
            let response = _processCreateListResponse(data);
            let isResponseOK = response && Object.keys(response).length > 0;

            if (isResponseOK) {
                _emitSyncCreateList(this, response);
                gtagEvent("trail_list_create", {
                    privacy: this.isPrivate ? "private" : "public",
                    item_name: this.nameInput,
                });

                // clean input on successfully creation
                _cleanInput(this);

                _emitCloseModal(this);
            }
        });
    }

    function _cleanDirtyValues(vueInstance) {
        _doubleRaf(() => {
            vueInstance.nameInputDirty = false;
            vueInstance.isPrivateDirty = false;
        });
    }

    function _emitNameList(vueInstance) {
        new Vue().$eventBus.$emit("crud-favlist:name", {
            name: vueInstance.nameInput,
        });
    }

    function editList() {
        // if nothing changed, just close the modal
        if (!this.nameInputDirty && !this.isPrivateDirty) {
            _emitCloseModal(this);
            return;
        }

        let url = window.location.origin + "/wikiloc/update-favorite-list.do";

        let params = "";
        params += "listId=" + this.listId;
        params += "&name=" + encodeURIComponent(this.nameInput);
        params += "&public=" + !this.isPrivate;

        _ajaxPost(url, params, (data) => {
            let response = _processUpdateListResponse(data);
            let isResponseOK = response && Object.keys(response).length > 0;

            if (isResponseOK) {
                _emitSyncEditList(this, response);
                _emitCloseModal(this);
                _cleanDirtyValues(this);
                _emitNameList(this);
            }
        });
    }

    function togglePrivate() {
        this.isPrivate = !this.isPrivate;
    }

    function onClickDelete() {
        if (!this.isEdit) {
            // only delete on edit mode
            return null;
        }

        // user-action.favorite-list.delete-favorite-list-help
        if (confirm(this.i18n.txtDeleteHelp)) {
            let url =
                window.location.origin + "/wikiloc/delete-favorite-list.do";
            let params = "listId=" + this.listId;

            _ajaxPost(url, params, (data) => {
                let response = data;
                let isResponseOK = response;

                if (isResponseOK) {
                    _emitSyncDeleteList(this, response);
                    _emitCloseModal(this);
                }
            });
        }
    }

    function onClickClose(event) {
        _emitCloseModal(this);
    }

    function onClickOutside(event) {
        // stop click propagation for parent modals
        // without this action, the clickOutside event is triggered in the parent modal, closing it as well.
        event.stopPropagation();
        let component = this.$refs.createListModalContent;

        //The click is outside the component
        if (component && !component.contains(event.target)) {
            _emitCloseModal(this);
        }
    }

    function footerClass() {
        let clazz = "crud-favlist__footer";

        return this.isEdit
            ? clazz + " crud-favlist__footer--multiple"
            : clazz + " crud-favlist__footer--single";
    }

    /**
     * ---- COMPUTED ATTRIBUTES ----
     * https://vuejs.org/v2/guide/computed.html#Computed-Properties
     */

    function isEdit() {
        return this.listId != null;
    }

    function inputCharLength() {
        return this.nameInput.length;
    }

    function title() {
        return this.isEdit ? this.i18n.txtEditList : this.i18n.txtNewList;
    }

    function mainActionText() {
        return this.isEdit ? this.i18n.txtUpdateList : this.i18n.txtCreateList;
    }

    /**
     * ------------------
     * ---- WATCHERS ----
     * ------------------
     * When Vue reactivity is not enough
     * https://vuejs.org/v2/guide/computed.html#Watchers
     */
    function isModalOpen(newIsModalOpen, oldIsModalOpen) {
        // doubleRaf required to sync events
        _doubleRaf(() => {
            if (newIsModalOpen) {
                _enableDocumentScroll(false);
                _addOnClickOutsideEvent(this);
                _autofocusNameInput(this);
            } else {
                _enableDocumentScroll(true);
                _rmOnClickOutsideEvent(this);
            }
        });
    }

    function nameInput(newNameInput, oldNameInput) {
        if (oldNameInput === "") {
            return false;
        } else {
            this.nameInputDirty = newNameInput !== oldNameInput;
        }
    }

    function isPrivate(newisPrivate, oldisPrivate) {
        this.isPrivateDirty = newisPrivate !== oldisPrivate;
    }

    /**
     * -----------------------
     * ---- VUE LIFECYCLE ----
     * -----------------------
     * https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
     */

    /**
     * Called after the instance has been mounted, where el is replaced by the newly created vm.$el.
     * If the root instance is mounted to an in-document element, vm.$el will also be in-document when mounted is called.
     *
     * Note that mounted does not guarantee that all child components have also been mounted.
     * If you want to wait until the entire view has been rendered, you can use vm.$nextTick inside of mounted
     */
    function mounted() {
        //1 - Native event Listeners

        // If edit, set data
        if (this.isEdit) {
            _fetchData(this.listId, this);
        }
    }

    function beforeDestroy() {
        //1 - Native event Listeners
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */
    /**
     * Perform an ajax call to retrieve the favorite list
     */
    function _fetchData(listId, vueInstance) {
        vueInstance.isLoadingResults = true;
        var url =
            window.location.origin + "/wikiloc/get-favorite-list-by-id.do";
        var params = "listId=" + listId;

        _ajaxPost(url, params, (data) => {
            vueInstance.isPrivate = data.isPrivate;
            vueInstance.nameInput = data.name
                .replace(/&amp;/g, "&")
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">");
            vueInstance.oldListName = vueInstance.nameInput;
            _cleanDirtyValues(vueInstance);
        });
    }
    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }

    function _emitSyncCreateList(vueInstance, createListResponse) {
        vueInstance.$emit("syncCreateList", createListResponse);
    }

    function _emitSyncEditList(vueInstance, editListResponse) {
        vueInstance.$emit("syncEditList", editListResponse);
    }

    function _emitSyncDeleteList(vueInstance, editDeleteResponse) {
        vueInstance.$emit("syncDeleteList", editDeleteResponse);
    }

    function _emitCloseModal(vueInstance) {
        vueInstance.$emit("sync-is-modal-open", {});
    }

    function _addOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.createListModal.addEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }

    function _rmOnClickOutsideEvent(vueInstance) {
        if (vueInstance.$refs.createListModal) {
            vueInstance.$refs.createListModal.removeEventListener(
                "click",
                vueInstance.onClickOutside
            );
        }
    }

    function _cleanInput(vueInstance) {
        vueInstance.nameInput = "";
        vueInstance.isPrivate = false;
    }

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let value = enable ? "auto" : "hidden";

        document.body.style.overflow = value;
    }

    /**
     * Set autofocus to the nameInput field
     */
    function _autofocusNameInput(vueInstance) {
        vueInstance.$refs.nameInputRef.focus();
    }

    /**
     *    Post Async ajax call
     */
    function _ajaxPost(url, formData, callback) {
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                try {
                    var data = JSON.parse(xmlhttp.responseText);
                } catch (err) {
                    console.error(err.message + " in " + xmlhttp.responseText);
                    return;
                }
                callback(data);
            }
        };

        xmlhttp.open("POST", url, true);
        xmlhttp.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded; charset=UTF-8"
        );
        xmlhttp.send(formData);
    }

    function _processCreateListResponse(result) {
        return _factoryCreateListResponse(result);
    }

    function _processUpdateListResponse(result) {
        return _factoryUpdateListResponse(result);
    }

    function _factoryCreateListResponse(response) {
        return new CreateListResponse(
            response.id,
            response.name,
            response.count,
            !response.isPublic
        );
    }

    function _factoryUpdateListResponse(response) {
        return new UpdateListResponse(
            response.id,
            response.name,
            !response.isPublic
        );
    }

    /**
     * --------------
     * ---- DTOs ----
     * --------------
     *
     */
    function CreateListResponse(id, name, count, isPrivate) {
        this.id = id;
        this.name = name;
        this.count = count;
        this.isPrivate = isPrivate;
    }

    function UpdateListResponse(id, name, isPrivate) {
        this.id = id;
        this.name = name;
        this.isPrivate = isPrivate;
    }
})();
